import React from 'react';
import { Box } from '@material-ui/core';
import { NavLink } from 'react-router-dom';

import '../css/landingpage.css';

const LandingPageSubHeader = () => {
  const scrollToSpecialites = () => {
    window.scrollTo({ top: 1950, behavior: 'smooth' });
  };
  return (
    <Box
      width='50%'
      marginLeft='50%'
      sx={{
        marginTop: '12px',
        backgroundColor: '#4083E0',
        borderBottomLeftRadius: '30px',
      }}>
      <Box
        display='flex'
        justifyContent='space-evenly'
        alignItems='center'
        height='32px'
        marginLeft='10px'
        className='sub-header'>
        <NavLink to='/signin' className='sub-header-item'>
          <Box className='sub-header-item'>Login as Doctor</Box>
        </NavLink>
        <Box>|</Box>
        <NavLink to='/signin' className='sub-header-item'>
          <Box className='sub-header-item'>Login as Patient</Box>
        </NavLink>
        <Box>|</Box>
        <NavLink to='/signup' className='sub-header-item'>
          <Box className='sub-header-item'>Join as Patient</Box>
        </NavLink>
        <Box>|</Box>
        <div onClick={scrollToSpecialites} className='sub-header-item'>
          Second Medical Opinion
        </div>
      </Box>
    </Box>
  );
};

export default LandingPageSubHeader;
