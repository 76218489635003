import { makeStyles, InputBase, withStyles } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import * as theme from './colorConstants';

const {
  colorMain,
  colorSecondaryText,
  colorSecondaryBackground,
  colorTextDarkGrey,
  accepted,
  rejected,
  colorMainHover,
  colorMainLigher,
  font,
} = theme;

export const displayBox = {
  fontFamily: font,
  fontWeight: '600',
  paddingBottom: '0px',
  color: '#4E4E5A',
  fontSize: '14px',
  marginTop: '0px',
};

export const buttonStyleNormalDottedBorder = {
  color: '#458FF6',
  border: '1px dashed #458FF6',
  borderRadius: '140px',
  height: '46px',
  fontFamily: font,
  fontWeight: 'bold',
  marginTop: '5px',
  marginLeft: '6px',
  paddingLeft: '40px',
  paddingRight: '40px',
  outline: 'none',
};

export const buttonStyleSelectedForward = {
  backgroundColor: colorMain,
  color: colorSecondaryText,
  width: '200px',
  fontFamily: font,
  marginTop: '64px',
  marginBottom: 'px',
};

export const buttonStyleSelectedMeasurements = {
  backgroundColor: colorMain,
  color: colorSecondaryText,
  fontFamily: font,
  outline: 'none',
  border: '1px solid ' + colorMain,
  fontSize: '12px',
};

export const buttonStyleSelected = {
  backgroundColor: colorMain,
  color: colorSecondaryText,
  fontFamily: font,
  outline: 'none',
  border: '1px solid ' + colorMain,
};

export const buttonStyleNormalMeasurements = {
  backgroundColor: colorSecondaryBackground,
  color: '#8B90A0',
  border: '1px solid #D3D4D8',
  fontFamily: font,
  outline: 'none',
  fontSize: '12px',
  background: '#EDF1FB',
};

export const buttonStyleNormal = {
  backgroundColor: colorSecondaryBackground,
  color: '#8B90A0',
  border: '1px solid #D3D4D8',
  fontFamily: font,
  outline: 'none',
};

export const buttonStyleSelectedForwardWithMargin = {
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '14px',
  lineHeight: '24px',
  backgroundColor: '#458FF6',
  color: colorSecondaryText,
  width: '160px',
  marginBottom: '16px',
  height: '48px',
  fontFamily: font,
  borderRadius: '8px',
  letterSpacing: '0.5px',
};

export const buttonStyleSelectedForwardWithMarginGrey = {
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '14px',
  lineHeight: '24px',
  backgroundColor: '#B1B5C2',
  color: colorSecondaryText,
  width: '160px',
  marginBottom: '16px',
  height: '48px',
  fontFamily: font,
  borderRadius: '8px',
  letterSpacing: '0.5px',
};

export const buttonStyleSelectedTopBottomMargin = {
  backgroundColor: '#4083E0',
  borderRadius: '6px',
  color: '#FFFFFF',
  width: '100%',
  /* marginTop: '20px',*/
  marginTop: '12px',
  fontFamily: font,
  fontWeight: '600',
  paddingTop: '16px',
  lineHeight: '15px',
  paddingBottom: '16px',
  letterSpacing: '0.03em',
};

export const buttonStyleSelectedTopBottomMarginDisabled = {
  backgroundColor: '#B1B5C2',
  borderRadius: '6px',
  color: '#FFFFFF',
  width: '100%',
  marginTop: '12px',
  fontFamily: font,
  fontWeight: '600',
  paddingTop: '16px',
  lineHeight: '15px',
  paddingBottom: '16px',
  letterSpacing: '0.03em',
};

export const buttonStyleAccepted = {
  backgroundColor: accepted,
  color: colorTextDarkGrey,
  padding: '8px 51px 8px 51px',
  fontFamily: font,
  fontWeight: 'bold',
  margin: '10px',
};

export const buttonCardUpload = {
  marginTop: '16px',
  backgroundColor: accepted,
  color: '#FFFFFF',
  padding: '8px 51px 8px 51px',
  fontFamily: font,
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '14px',
  lineHeight: '24px',
  letterSpacing: '0.5px',
  width: '100%',
  background: '#4083E0',
  borderRadius: '6px',
};

export const buttonStyleRejected = {
  backgroundColor: rejected,
  color: colorTextDarkGrey,
  padding: '8px 59px 8px 59px',
  fontFamily: font,
  fontWeight: 'bold',
  margin: '10px',
};

export const displayButtonStyle = {
  backgroundColor: colorMain,
  color: colorSecondaryText,
  borderRadius: '44px',
  padding: '10px',
  margin: '1px',
  marginTop: '5px',
  fontFamily: font,
};
export const buttonStyleSelectedConsultation = {
  backgroundColor: '#4083E0',
  color: colorSecondaryText,
  marginTop: '15px',
  marginBottom: '40px',
  paddingTop: '12px',
  paddingBottom: '12px',
  paddingLeft: '20px',
  paddingRight: '20px',
  fontFamily: font,
  borderRadius: '6px',
  fontWeight: '600',
  lineHeight: '15px',
  letterSpacing: '0.03em',
};

export const buttonStyleSelectedTable = {
  background: '#E2EEFF',
  borderRadius: '6px',
  color: '#3699FF',
  paddingTop: '8px',
  paddingBottom: '11px',
  paddingLeft: '34px',
  paddingRight: '34px',
  fontWeight: 'bold',
  fontSize: '12px',
  lineHeight: '15px',
  fontFamily: font,
  textAlign: 'right',
};

export const buttonStyleActivatedLighter = {
  backgroundColor: colorMainLigher,
  color: colorSecondaryText,
  borderRadius: '20px',
  padding: '8px 20px 8px 20px',
  fontSize: '14px',
  lineHeight: '24px',
  margin: '4px',
  marginTop: '5px',
  outline: 'none',
  fontFamily: font,
};

export const cardButton = {
  backgroundColor: colorMain,
  color: colorSecondaryText,
  fontFamily: font,
  padding: '8px 40px 8px 40px',
  margin: '10px',
  fontWeight: '600',
  fontSize: '14px',
  lineHeight: '24px',
  borderRadius: '6px',
};

export const cardButtonProfile = {
  background: '#E2EEFF',
  color: '#3699FF',
  fontFamily: font,
  padding: '8px 40px 8px 40px',
  margin: '10px',
  fontWeight: '600',
  fontSize: '14px',
  lineHeight: '24px',
  borderRadius: '6px',
};
export const buttonStyleDisabled = {
  backgroundColor: colorSecondaryBackground,
  color: '#8B90A0',
  border: '1px solid #8B90A0',
  borderRadius: '20px',
  padding: '8px 20px 8px 20px',
  margin: '4px',
  marginTop: '5px',
  fontFamily: font,
  fontSize: '14px',
  outline: 'none',
  lineHeight: '24px',
};

export const buttonStyleDisabled2 = {
  backgroundColor: colorSecondaryBackground,
  color: '#8B90A0',
  border: '1px solid #8B90A0',
  borderRadius: '8px',
  padding: '8px 20px 8px 20px',
  margin: '4px',
  marginTop: '5px',
  fontFamily: font,
  fontSize: '14px',
  outline: 'none',
  lineHeight: '24px',
};

export const buttonStyleHover = {
  backgroundColor: colorSecondaryBackground,
  borderRadius: '20px',
  padding: '8px 20px 8px 20px',
  margin: '4px',
  marginTop: '5px',
  fontFamily: font,
  fontSize: '14px',
  outline: 'none',
  lineHeight: '24px',
  color: '#458FF6',
  border: '1px solid #458FF6',
};

export const dropDownStyle = {
  fontWeight: 'bold',
  fontFamily: 'geomanistregular',
  color: '#4E4E5A',
};

export const buttonStyleActivated = {
  backgroundColor: colorMain,
  border: '1px solid ' + colorMain,
  color: colorSecondaryText,
  borderRadius: '20px',
  padding: '8px 20px 8px 20px',
  margin: '4px',
  marginTop: '5px',
  outline: 'none',
  fontFamily: font,
  fontSize: '14px',
  lineHeight: '24px',
};

export const buttonStyleActivated2 = {
  backgroundColor: colorMain,
  border: '1px solid ' + colorMain,
  color: colorSecondaryText,
  borderRadius: '8px',
  padding: '8px 20px 8px 20px',
  margin: '4px',
  marginTop: '5px',
  outline: 'none',
  fontFamily: font,
  fontSize: '14px',
  lineHeight: '24px',
};

export const useStyles = makeStyles((theme) => ({
  largeScreen: useMediaQuery((theme) => theme.breakpoints.up('md')),

  root: {
    display: 'flex',
    marginTop: '12px',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    closeButton: {
      position: 'relat0ive',
      right: '0px',
      top: '0px',
      color: 'grey',
      justifyContent: 'flex-end',
      '&:hover': {
        backgroundColor: 'white',
      },
    },
  },
  gridOne: {
    justifyContent: useMediaQuery((theme) => theme.breakpoints.up('md')) ? 'flex-end' : 'space-between',
  },
  gridTwo: {
    justifyContent: useMediaQuery((theme) => theme.breakpoints.up('md')) ? 'flex-start' : 'space-between',
  },
}));

export const selectPhysicanStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },

    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
  },
  formControl: {
    marginTop: theme.spacing(1),
    width: '200px',
  },
  search: {
    background: '#FFFFFF',
    marginTop: theme.spacing(1),
    borderRadius: `8px`,
    '& input': {
      color: '#4E4E5A',
      fontFamily: 'geomanistregular',
      height: '100%',
      maxWidth: '304px',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: `8px`,
        border: '1px solid #F0F1F3',
      },
      '& label.Mui-focused': {
        color: '#458FF6',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#458FF6',
      },
      /*'&.Mui-focused input': {
        color: '#458FF6',
        background: '#F0F4FF',
      },*/
      '&:hover fieldset': {},
      '& .MuiInput-underline:after': {},
    },
    '& placeholder': {
      color: '#727279',
    },
  },
}));

export const popUpStyle = makeStyles((theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'relative',
    right: '0px',
    top: '0px',
    color: 'grey',
    justifyContent: 'flex-end',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
}));

export const forgetPasswordStyle = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '8px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: 'none',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: colorMain,
    '&:hover': {
      backgroundColor: colorMainHover,
    },
  },
}));

export const lineStyles = makeStyles({
  root: {
    width: '100%',
    position: 'absolute',
    top: '0px',
    backgroundColor: colorMain,
  },
  barRoot: {
    backgroundColor: '#CDCDCD',
  },
  bar: {
    background: colorMain,
  },
});

export const signupStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    fontFamily: font,
    height: '48px',
    outline: 'none',
    border: 'none',
    backgroundColor: colorMain,
    '&:hover': {
      backgroundColor: colorMainHover,
    },
  },
  submitSmaller: {
    margin: theme.spacing(3, 0, 2),
    width: '30%',
    fontFamily: font,
    height: '48px',
    marginRight: '20px',
    outline: 'none',
    border: 'none',
    backgroundColor: colorMain,
    '&:hover': {
      backgroundColor: colorMainHover,
    },
  },
  formControl: {
    marginTop: theme.spacing(1),
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export const signinStyle = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(30),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    fontFamily: font,
    backgroundColor: colorMain,
    outline: 'none',
    border: 'none',
    height: '48px',
    '&:hover': {
      backgroundColor: colorMainHover,
    },
  },
  typography: {
    fontFamily: 'var(--font)',
    lineHeight: '24px',
    marginTop: '20px',
    letterSpacing: '.5px',
    textAlign: 'left',
    display: 'inline-block',
    marginBottom: '12px',
    width: '100%',
    color: '#273238',
    fontWeight: 600,
  },
}));

export const notificationStyle = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 480,
    backgroundColor: theme.palette.background.paper,
  },
}));

export const messagesStyle = makeStyles((theme) => ({
  root: {
    width: '95%',
    marginTop: 5,
    backgroundColor: theme.palette.background.paper,
  },
}));

export const codeVerificationStyle = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '8px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: 'none',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: colorMain,
    '&:hover': {
      backgroundColor: colorMainHover,
    },
  },
}));

export const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': { fontFamily: 'geomanistregular' },
    maxWidth: '218px',
  },
  input: {
    borderRadius: 8,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #F0F1F3',
    fontSize: 14,
    padding: '13px 44px 13px 26px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: 'geomanistregular',
    '&:focus': {
      borderRadius: 8,
      backgroundColor: 'white',
    },
  },
}))(InputBase);

export const QuestionsInput = withStyles((theme) => ({
  root: {
    'label + &': { fontFamily: 'geomanistregular' },
  },

  input: {
    borderRadius: 8,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #c4c4c4',
    fontSize: '14px',

    padding: '13px 44px 13px 15px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: 'geomanistregular',
    '&:focus': {
      borderRadius: 8,
      backgroundColor: 'white',
    },
  },
}))(InputBase);
