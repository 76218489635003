import React, { lazy, Suspense } from 'react';
import Amplify from 'aws-amplify';
import ReactGA from 'react-ga4';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.css';

import awsconfig from './aws-exports';
import Loading from './components/Loading';
import PageLayout from './layouts/PageLayout';

import LandingPage from './modules/home/LandingPage';

const SignIn = lazy(() => import('./components/SignIn'));
const SignUp = lazy(() => import('./components/SignUp'));
const Specialities = lazy(() => import('./modules/home/AllSpecialities'));
const CodeVerification = lazy(() => import('./components/CodeVerification'));
const MedicalProfile = lazy(() => import('./modules/medicalprofile/MedicalProfileContainer'));
const DisplayMedicalProfile = lazy(() => import('./modules/viewmedicalprofile/DisplayMedicalProfileContainer'));
const SelectPhysician = lazy(() => import('./modules/selectphysician/SelectPhysicianContainer'));
const AuthorizeRelease = lazy(() => import('./modules/release/AuthorizeRelease'));
const ConsultancyRequest = lazy(() => import('./modules/consultancyform/ConsultancyRequestContainer'));
const ConsultationRequests = lazy(() => import('./modules/consultationrequestlist/ConsultationRequests'));
const PreviousMedicalHistory = lazy(() => import('./modules/medicaldocs/PreviousMedicalHistoryContainer'));
const OpinionRequests = lazy(() => import('./modules/opinionrequest/OpinionRequestsContainer'));
const ProfileContainer = lazy(() => import('./modules/profile/ProfileContainer'));
const UnsubmittedConsultationContainer = lazy(() =>
  import('./modules/unsubmittedConsultations/UnsubmittedConsultationContainer')
);
const SubmittedConsultationContainer = lazy(() =>
  import('./modules/submittedConsultations/SubmittedConsultationContainer')
);
const ScrollToTop = lazy(() => import('./components/ScrollToTop'));
const PasswordForm = lazy(() => import('./modules/changepassword/PasswordForm'));
const ProfileChangeRequestsContainer = lazy(() => import('./modules/approvalrequests/ProfileChangeRequestsContainer'));
const DummyAccountsContainer = lazy(() => import('./modules/createDummyAccounts/DummyAccountsContainer'));
const MedicalContainer = lazy(() => import('./modules/addmedicaldata/MedicalContainer'));
const InviteContainer = lazy(() => import('./modules/createInvites/InviteContainer'));
const DetailsContainer = lazy(() => import('./modules/physiciandetails/DetailsContainer'));
const AccountDifference = lazy(() => import('./modules/approvalrequests/AccountDifference'));
const ChatContainer = lazy(() => import('./modules/chatcontainer/ChatContainer'));
const Payment = lazy(() => import('./modules/payment'));

const PaymentSuccess = lazy(() => import('./modules/payment/PaymentSuccess'));

const TermsAndCondition = lazy(() => import('./modules/termsandcondition/TermsAndCondition'));
const PrivacyPolicy = lazy(() => import('./modules/privacypage/Privacy'));
const Disclaimer = lazy(() => import('./modules/disclaimer/Disclaimer'));
const AboutUs = lazy(() => import('./modules/aboutUsPage/AboutUs'));
const Vlogs = lazy(() => import('./modules/vlogs/Vlogs'));
const RefundPolicy = lazy(() => import('./modules/refundPolicy/RefundPolicy'));

const ContactUs = lazy(() => import('./modules/contactus/ContactUs'));
const NotFound = lazy(() => import('./components/NotFound'));

Amplify.configure(awsconfig);

ReactGA.initialize(process.env.REACT_APP_GOOGLE_GTAG_KEY);

function App() {
  return (
    <div className='App'>
      <div>
        <BrowserRouter>
          <Suspense fallback={<Loading></Loading>}>
            <ScrollToTop />
            <Switch>
              <Route path='/' component={LandingPage} exact />
              <Route path='/medicalprofile' component={MedicalProfile} />
              <Route path='/payment' component={Payment} />
              <Route path='/viewmedicalprofile' component={DisplayMedicalProfile} />
              <Route path='/codeverification/:type?/:id?' component={CodeVerification} />
              <Route path='/disclaimer' component={AuthorizeRelease} />
              <Route path='/consultancy' component={ConsultancyRequest} />
              <Route path='/previousrecords' component={PreviousMedicalHistory} />
              <Route path='/yourrequests' component={ConsultationRequests} />
              <Route path='/opinionrequests/:id' component={OpinionRequests} />
              <Route path='/unsubmittedopinionrequest' component={UnsubmittedConsultationContainer} />
              <Route path='/submittedopinionrequest' component={SubmittedConsultationContainer} />
              <Route path='/profile' component={ProfileContainer} />
              <Route path='/changepassword/:type?/:id?' component={PasswordForm} />
              <Route path='/specialities' component={Specialities} exact />
              <Route path='/signup/:type?/:id?' component={SignUp} exact />
              <Route path='/signin/:type?/:id?' component={SignIn} />
              <Route path='/profilerequests' component={ProfileChangeRequestsContainer} />
              <Route path='/createaccount' component={DummyAccountsContainer} />
              <Route path='/paymentsuccess' component={PaymentSuccess} />
              <Route path='/add' component={MedicalContainer} />
              <Route path='/invite' component={InviteContainer} />
              <Route path='/physician/:id' component={DetailsContainer} />
              <Route path='/message/:id' component={ChatContainer} />
              <Route path='/physicianchanges/:id' component={AccountDifference} />
              <Route exact path='/termsandcondition'>
                <PageLayout>
                  <TermsAndCondition />
                </PageLayout>
              </Route>
              <Route exact path='/privacypolicy'>
                <PageLayout>
                  <PrivacyPolicy />
                </PageLayout>
              </Route>
              <Route exact path='/disclaimer-page'>
                <PageLayout>
                  <Disclaimer />
                </PageLayout>
              </Route>
              <Route exact path='/contactus'>
                <PageLayout>
                  <ContactUs />
                </PageLayout>
              </Route>
              <Route exact path='/about-us'>
                <PageLayout>
                  <AboutUs />
                </PageLayout>
              </Route>
              <Route exact path='/vlogs'>
                <PageLayout>
                  <Vlogs />
                </PageLayout>
              </Route>
              <Route exact path='/return-refund-policy'>
                <PageLayout>
                  <RefundPolicy />
                </PageLayout>
              </Route>

              <Route path='/selectphysician/:id?' component={SelectPhysician} />
              <Route path='/:medicalspeciality/:physician' component={DetailsContainer} />
              <Route path='/:slug?' component={SelectPhysician} />
              <Route path='/*' component={NotFound} exact />
            </Switch>
          </Suspense>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
