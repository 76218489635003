import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import { Badge, useMediaQuery, Box } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import Logo from '../resources/logo.svg';
import home from '../resources/home.svg';
import threeDotMenu from '../resources/three-dot-icon.svg';
import dummyProfile from '../resources/dummy-profile.svg';
import expertbell from '../resources/expertbell.svg';
import NotificationsList from '../components/NotificationsList';
import { notificationGet } from '../data';
import DropDownMenu from './DropDownMenu';
import { servicesList, mediaList } from '../content/headerData';
import { getIsLogged, getUser, getToken } from '../selectors';
import { getFullName, signUserOut } from '../utilities/helperFunctions';

import '../css/landingpage.css';
import '../css/header.css';

const LandingPageHeader = (props) => {
  const [modal, setModal] = useState(false);
  const [showNotificationsList, setShowNotificationsList] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [notificationCount, setNotificationsCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);
  const [initialData, setInitialData] = useState(false);

  const isLogged = useSelector((state) => getIsLogged(state));
  const user = useSelector((state) => getUser(state));
  const token = useSelector((state) => getToken(state));
  const data = useSelector((state) => state.notificationResult);
  const { loading, result } = data;

  const picture = user?.picture || dummyProfile;
  const dispatch = useDispatch();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const isTablet = useMediaQuery(theme.breakpoints.up('xs') && theme.breakpoints.down('md'));

  function hideNotifications() {
    setShowNotificationsList(false);
  }

  function reloadNotifications(limit) {
    setLimit(limit);
    setInitialData(true);
    const apiData = { token, limit };
    dispatch(notificationGet(apiData));
  }

  useEffect(() => {
    if (!initialData) {
      const apiData = { token, limit };
      dispatch(notificationGet(apiData));
      setInitialData(true);
    } else if (
      !loading &&
      result &&
      result.response &&
      result.response.constructor === Array &&
      result.response.length > 0
    ) {
      setNotifications(result.response);
      setNotificationsCount(result.count);
      setCount(result.totalCount);
      setDataLoaded(true);
    }
  }, [loading, initialData]);

  return (
    <>
      <Box width={'88%'} display='flex' mt={1.5} container justifyContent='space-between' alignItems='center'>
        <NavLink to='/'>
          <img src={Logo} style={{ cursor: 'pointer' }} alt='company logo' width={216} height={56} />
        </NavLink>

        {isMobile | isTablet ? (
          <img
            src={threeDotMenu}
            alt='three dot icon'
            style={{ cursor: 'pointer' }}
            onClick={() => setModal(!modal)}
            width={22}
            height={16}
          />
        ) : (
          <Box
            direction='row'
            justifyContent='flex-end'
            alignItems='center'
            display='flex'
            width={{ xs: '80%', xl: '60%' }}
            maxWidth='720px'>
            {isLogged ? (
              <Box display='flex' flexDirection='row'>
                <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
                  <NavLink to='/' style={{ margin: 'auto 0px' }}>
                    <img
                      src={home}
                      alt='home'
                      style={{
                        marginBottom: '20px',
                        marginRight: '20px',
                      }}
                      width={30}
                      height={30}
                    />
                  </NavLink>
                  <NavLink className='profile-redirect' to='/profile'>
                    <Box className='profile-box'>Profile</Box>
                  </NavLink>

                  <Box className='account-dropdown'>
                    <img
                      src={picture}
                      className='header-image img-vertical-align'
                      alt='profile'
                      width='40'
                      height='40'
                      style={{ borderRadius: '100%', objectFit: 'cover' }}
                    />
                    <Box className='align-user-info'>
                      <span className='text-decorator-white'>{getFullName(user)}</span>
                      <span className='text-decorator-grey' style={{ fontWeight: 600 }}>
                        {user.type}
                      </span>
                    </Box>
                    <Box className='account-dropdown-content'>
                      <Box className='sign-out-element-1'>
                        <a>
                          <NavLink to='/profile'>Profile</NavLink>
                        </a>
                      </Box>
                      <Box className='sign-out-element'>
                        <a href='' onClick={signUserOut}>
                          Sign out
                        </a>
                      </Box>
                    </Box>
                  </Box>

                  <Box onClick={() => setShowNotificationsList(!showNotificationsList)} sx={{ ml: 3 }}>
                    <Badge
                      style={{
                        width: '20px',
                        height: '20px',
                        marginBottom: '24px',
                      }}
                      badgeContent={dataLoaded ? notificationCount : 0}
                      color='secondary'
                      max={99}>
                      <img src={expertbell} className='header-image' alt='profile' width='27' height='27' />
                    </Badge>
                  </Box>

                  <NavLink to='/contactus' style={{ marginBottom: '20px', marginLeft: '32px', marginRight: '16px' }}>
                    <Box component='button' className='join-patient-button'>
                      Contact Us
                    </Box>
                  </NavLink>
                </Box>
                {showNotificationsList ? (
                  <NotificationsList
                    count={count}
                    show
                    setOpen={hideNotifications}
                    reload={reloadNotifications}
                    history={props.history}
                    notifications={notifications}
                    limit={limit}
                  />
                ) : null}
              </Box>
            ) : (
              <Box display='flex' width='77%' flexDirection='row' alignItems='center'>
                <NavLink to='/' className='login-doctor-link link'>
                  Home
                </NavLink>
                <DropDownMenu heading='Services' menuList={servicesList} />
                <DropDownMenu heading='Media' menuList={mediaList} />
                <NavLink className='login-doctor-link link' to='/contactus'>
                  Contact Us
                </NavLink>
                <NavLink className='login-doctor-link link' to='/about-us'>
                  About Us
                </NavLink>
              </Box>
            )}
          </Box>
        )}
      </Box>

      {modal && (
        <Box
          width='100%'
          zIndex={99}
          position='absolute'
          top={'160px'}
          className='overlay'
          display={'flex'}
          flexDirection='column'
          justifyContent={'center'}
          alignItems='center'
          sx={{ pt: 2, pb: 1 }}>
          {!isLogged ? (
            <Box zIndex={99}>
              <Box sx={{ m: 3 }}>
                <NavLink className='login-patient-link' to='/'>
                  Go to home
                </NavLink>
              </Box>
              <Box sx={{ m: 3 }}>
                <NavLink className='login-patient-link' to='/signin'>
                  Login as Doctor
                </NavLink>
              </Box>
              <Box sx={{ m: 3 }}>
                <NavLink className='login-patient-link' to='/signin'>
                  Login as Patient
                </NavLink>
              </Box>
              <Box sx={{ m: 3 }}>
                <NavLink className='login-patient-link' to='/signup'>
                  Join as Patient
                </NavLink>
              </Box>
              <Box sx={{ m: 3 }}>
                <DropDownMenu heading='Services' menuList={servicesList} />
              </Box>
              <Box sx={{ m: 3 }}>
                <DropDownMenu heading='Media' menuList={mediaList} />
              </Box>
              <Box sx={{ m: 3 }}>
                <NavLink className='login-patient-link' to='/contactus'>
                  Contact Us
                </NavLink>
              </Box>
              <Box sx={{ m: 3 }}>
                <NavLink className='login-patient-link' to='/about-us'>
                  About Us
                </NavLink>
              </Box>
            </Box>
          ) : (
            <Box>
              <Box sx={{ m: 4 }}>
                <NavLink className='login-doctor-link' to='/profile'>
                  Profile
                </NavLink>
              </Box>

              <Box sx={{ m: 4 }}>
                <NavLink className='login-patient-link' to='/signin'>
                  <a href='' onClick={signUserOut}>
                    Sign out
                  </a>
                </NavLink>
              </Box>
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default LandingPageHeader;
