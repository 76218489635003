import facebookIcon from '../resources/facebook-icon.svg';
import twitterIcon from '../resources/twitter-icon.svg';
import linkedinIcon from '../resources/linkedin-icon.svg';
import tiktokIcon from '../resources/tiktok-icon.svg';
import youtubeIcon from '../resources/youtube-icon.svg';
import instagramIcon from '../resources/instagram-icon.svg';

// TODO: Links are yet to be added when the client provides them.
export const supportData = [
  { name: 'Contact Us', link: '/contactus' },
  { name: 'Disclaimer', link: '/disclaimer-page' },
  { name: 'FAQs', link: '' },
  { name: 'Terms of Use', link: '/termsandcondition' },
  { name: 'Privacy Policy', link: '/privacypolicy' },
  { name: 'Refund Policy', link: '/return-refund-policy' },
];
export const resourcesData = [
  { name: 'Health Tips', link: '/#health-tips' },
  { name: 'Healthcare News', link: '/#health-tips' },
  { name: 'Vlogs', link: '/vlogs' },
  { name: 'Blogs', link: '/' },
  { name: 'Review By Patients', link: '/#review-by-patients' },
];

export const connectWithUsData = [{ name: 'About Us', link: '/about-us' }];

export const footerHeading = {
  HOME: 'Home',
  SERVICES: 'Services',
  SUPPORT: 'Support',
  RESOURCES: 'Resources',
  CONNECT_WITH_US: 'Connect with Us',
};

export const socialMediaData = [
  { altText: 'Facebook icon for contacting us', link: 'https://www.facebook.com/ourexpertdoc1', icon: facebookIcon },
  { altText: 'Icon for contacting us on X', link: 'https://twitter.com/ourexpertdoc', icon: twitterIcon },
  { altText: 'LinkedIn icon for contacting us', link: 'https://www.linkedin.com/in/ourexpertdoc/', icon: linkedinIcon },
  { altText: 'Tiktok icon for contacting us', link: 'https://www.tiktok.com/@ourexpertdoc', icon: tiktokIcon },
  { altText: 'Youtube icon for contacting us', link: 'https://www.youtube.com/@ourexpertdoc', icon: youtubeIcon },
  { altText: 'Instagram icon for contacting us', link: 'https://www.instagram.com/ourexpertdoc/', icon: instagramIcon },
];
