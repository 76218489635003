import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import Carousel from './Carousel';
import patientIcon from '../../resources/patienticon.svg';
import '../../css/landingpage.css';

const PatientReviews = (props) => {
  const { mode } = props;
  const [currentCard, setCurrentCard] = useState(0);
  const Reviews = [
    {
      review:
        'I have consulted Dr Kamran Mahmood at OurExpertDoc multiple times for the management of my ILD. I have found him professional and highly knowledgeable in his field of pulmonology. He is also very considerate, compassionate, and always ready to help and give advice whenever I need it. He always gives me ample time to put forth my concerns and thoroughly addresses them with compassion. I am entirely satisfied with his care',
      patient: 'RZ',
    },
    {
      review:
        "I would definitely recommend it to my family and friend list, because of its easy to use website, the Drs' responses are very quick. They provided detailed response, assessment and recommended plan and also useful literature links. Moreover, the website is very handy, we can add complete patient medical records in multiple formats",
      patient: 'AM',
    },
    {
      review:
        'I sought a second opinion from Dr. Kamran Mahmood at OurExpertDoc.com regarding my father’s acute respiratory failure while he was admitted in the ICU. Dr. Mahmood carefully reviewed all the reports I uploaded on the portal, provided a comprehensive overview of the condition, and shared treatment recommendations in detail. This experience helped me navigate my father’s treatment and helped me with decision-making process at different steps. He stayed engaged and followed up with him until he recovered and discharged. I highly recommend OurExpertDoc for anyone seeking expert medical advice from top-notch doctors. The clarity and confidence I gained during this process were invaluable during a challenging time.',
      patient: 'NA',
    },
    {
      review:
        ' I was diagnosed with COVID-19 and was very concerned. Having recently been vaccinated I was not sure how I would do. I reached out to Dr M. for his opinion. He was prompt and thorough  with his advice, which relieved a lot of my stress and helped me get better. I refer my family and friends to him for an expert opinion.',
      patient: 'MD',
    },
    {
      review:
        'I was visiting Boston and was diagnosed with a life-threatening illness. Although I had access to local doctors, I needed a second opinion to come up with the best possible treatment plan. I reached out to Dr M and sent him my clinical information and imaging. He consulted an expert in his institution and gave me timely recommendations, which helped me come up with a plan personalized for my illness and receive best possible therapy.',
      patient: 'IA',
    },
  ];

  return (
    <Carousel
      markup={
        mode === 'mob' ? (
          <Box
            height='380px'
            width='100%'
            borderRadius='20px'
            className='patient-review-card shadow-interaction'
            display='flex'
            flexDirection='column'
            alignItems='space-between'
            justifyContent='space-between'
            sx={{ p: 4 }}>
            <Box component={'span'} className='patient-review-text'>
              {Reviews[currentCard].review}
            </Box>
            <Box display='flex' flexDirection='row' justifyContent='space-between'>
              <Box display='flex' flexDirection='column'>
                <span className='patient-name'>{Reviews[currentCard].patient}</span>
              </Box>
              <img src={patientIcon} alt='patient icon' height='26px' width='26px' />
            </Box>
          </Box>
        ) : (
          <Box display='flex' alignItems='center' justifyContent='center' gap={2}>
            <Box
              width='78%'
              borderRadius='20px'
              className='patient-review-card shadow-interaction'
              display='flex'
              flexDirection='column'
              alignItems='space-between'
              justifyContent='space-around'
              sx={{ p: 4 }}>
              <Box component={'span'} className='patient-review-text'>
                {Reviews[currentCard].review}
              </Box>
              <Box display='flex' flexDirection='row' justifyContent='space-between'>
                <Box display='flex' flexDirection='column'>
                  <span className='patient-name'>{Reviews[currentCard].patient}</span>
                </Box>
                <img alt='patient icon' src={patientIcon} height='25px' width='25px' />
              </Box>
            </Box>
          </Box>
        )
      }
      currentCard={currentCard}
      setCurrentCard={setCurrentCard}
      Reviews={Reviews}
      arrow={mode === 'mob' ? false : true}
    />
  );
};

export default PatientReviews;
