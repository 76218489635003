import React from 'react';
import { Helmet } from 'react-helmet';
import { Grid, Box } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import LazyLoad from 'react-lazyload';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import PatientReviews from './PatientReviews';
import NewsArticles from './NewsArticles';
import asset2 from '../../resources/asset2.webp';
import asset3_1 from '../../resources/asset3-1.svg';
import asset3_2 from '../../resources/asset3-2.svg';
import asset3_3 from '../../resources/asset3-3.svg';
import asset3_4 from '../../resources/asset3-4.svg';
import asset3_5 from '../../resources/asset3-5.svg';
import WhatsAppIcon from '../../components/WhatsappButton';
import howOedworksImage from '../../resources/how-oed-work.webp';

import LandingPageFooter from '../../components/LandingPageFooter';
import LandingPageHeader from '../../components/LandingPageHeader';
import LandingPageSubHeader from '../../components/LandingPageSubHeader';
import Specialities from '../specialities/Specialities';
import PricingCard from './PricingCard';

import '../../css/landingpage.css';

const LandingPage = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const isTablet = useMediaQuery(theme.breakpoints.up('xs') && theme.breakpoints.down('md'));

  const month = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const d = new Date();
  let monthName = month[d.getMonth()];

  return (
    <>
      <Helmet>
        <title>Online Medical Consultation & Second Opinions | OurExpertDoc</title>
        <meta
          name='description'
          content='Get high-quality online medical consultations and trusted second medical opinions from licensed US-based doctors. Convenient, reliable care at your fingertips.'
        />
      </Helmet>
      <Grid
        xs={12}
        container
        display='flex'
        justifyContent='center'
        alignItems='center'
        style={{ flexDirection: 'column' }}>
        <Box className='top-banner'>
          <p className='banner-text'>
            Use coupon <b>50Percent</b> to get a <b>50% discount</b> on a consultation during {monthName}
          </p>
        </Box>
        {!isTablet && <LandingPageSubHeader />}

        <LandingPageHeader />
        <WhatsAppIcon />
        <Grid xs={10} display='flex' container justifyContent='space-between' alignItems='center'>
          <Box sx={{ mt: 12 }} display='flex' width='100%' className='first-section' zIndex={2}>
            <Box display='flex' flexDirection='column' className='first-section-content'>
              <Box component={'span'} sx={{ color: '#5181d9' }} className='first-section-text'>
                Expert Medical Opinions
              </Box>
              <span className='first-section-text'> Anywhere in the World</span>
              <Box component={'h1'} className='first-section-subheading' mt={2}>
                Trusted Online Medical Consultation by Board Certified USA Doctors
              </Box>
            </Box>

            <img
              src='/assets/asset1.webp'
              className='first-section-image'
              width={200}
              height={500}
              alt='hero section image'
              loading='eager'
              fetchpriority='high'
            />
          </Box>
        </Grid>

        <Grid xs={10}>
          <Box
            display='flex'
            justifyContent='center'
            width='100%'
            flexDirection='row'
            alignItems='center'
            sx={{ mt: 16 }}>
            <span className='heading-normal'>
              Why <span className='second-section-text-bold'>OurExpertDoc</span>
            </span>
          </Box>
          <Box component={'h2'} className='why-section-subheading' sx={{ mt: 2, mb: 3 }}>
            Why Choose OurExpertDoc for Online Medical Consultations and Second Opinions
          </Box>
          <Box
            display='flex'
            width='100%'
            alignItems='center'
            className='second-section'
            justifyContent='space-between'>
            <Box className='second-section-asset'>
              <LazyLoad height={120} offset={100}>
                <img src={asset2} alt='doctors icon' width='100%' height='auto' loading='lazy' />
              </LazyLoad>
            </Box>

            <Box
              display='flex'
              justifyContent='center'
              className='second-section-content'
              flexDirection='row'
              alignItems='center'>
              <Box display='flex' justifyContent='space-between' flexDirection='column' alignItems='center'>
                <Box display='flex' direction='row' width='100%'>
                  <Box
                    width='30px'
                    height='30px'
                    className='sec-3-icon-cont'
                    bgcolor='#D8DCF8'
                    borderRadius='10px'
                    sx={{ p: 4 }}
                    display='flex'
                    justifyContent='center'
                    alignItems='center'>
                    <img src={asset3_1} alt='thumbsup icon' width={100} height={25} loading='lazy' />
                  </Box>
                  <Box display='flex' flexDirection='column' sx={{ ml: 2 }}>
                    <span className='section-3-heading'>Access to World Experts</span>
                    <span className='section-3-text'>International experts directing your care</span>
                  </Box>
                </Box>
                <Box sx={{ mt: 2 }} display='flex' direction='row' width='100%'>
                  <Box
                    width='30px'
                    height='30px'
                    className='sec-3-icon-cont'
                    bgcolor='#FBE7D3'
                    borderRadius='10px'
                    sx={{ p: 4 }}
                    display='flex'
                    justifyContent='center'
                    alignItems='center'>
                    <img src={asset3_3} alt='clock icon' width={100} height={25} loading='lazy' />
                  </Box>
                  <Box display='flex' flexDirection='column' sx={{ ml: 2 }}>
                    <span className='section-3-heading'>Convenient</span>
                    <span className='section-3-text'>Less time in clinics = more time for living</span>
                  </Box>
                </Box>
                <Box sx={{ mt: 2 }} display='flex' direction='row' width='100%'>
                  <Box
                    width='30px'
                    height='30px'
                    className='sec-3-icon-cont'
                    bgcolor='#DAF9E5'
                    borderRadius='10px'
                    sx={{ p: 4 }}
                    display='flex'
                    justifyContent='center'
                    alignItems='center'>
                    <img src={asset3_2} alt='dollar icon' width={100} height={25} loading='lazy' />
                  </Box>
                  <Box display='flex' flexDirection='column' sx={{ ml: 2 }}>
                    <span className='section-3-heading'>Affordable</span>
                    <span className='section-3-text'>Know what you pay for and how you pay</span>
                  </Box>
                </Box>
                <Box sx={{ mt: 2 }} display='flex' direction='row' width='100%'>
                  <Box
                    width='30px'
                    height='30px'
                    className='sec-3-icon-cont'
                    bgcolor='#f8dfe2'
                    borderRadius='10px'
                    sx={{ p: 4 }}
                    display='flex'
                    justifyContent='center'
                    alignItems='center'>
                    <img src={asset3_4} alt='security shield icon' width={100} height={45} loading='lazy' />
                  </Box>
                  <Box display='flex' flexDirection='column' sx={{ ml: 2 }}>
                    <span className='section-3-heading'>Electronic Medical Record</span>
                    <span className='section-3-text'>
                      Personalized, secure, HIPAA compliant electronic medical record
                    </span>
                  </Box>
                </Box>
                <Box sx={{ mt: 2 }} display='flex' direction='row' width='100%'>
                  <Box
                    width='30px'
                    height='30px'
                    className='sec-3-icon-cont'
                    bgcolor='#e2edfe'
                    borderRadius='10px'
                    sx={{ p: 4 }}
                    display='flex'
                    justifyContent='center'
                    alignItems='center'>
                    <img src={asset3_5} alt='plane icon' width={100} height={45} loading='lazy' />
                  </Box>
                  <Box display='flex' flexDirection='column' sx={{ ml: 2 }}>
                    <span className='section-3-heading'>Travel Facilitation</span>
                    <span className='section-3-text'>Facilitate travel to USA for medical care</span>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid xs={10}>
          <PricingCard />
        </Grid>

        <Grid xs={10}>
          <Specialities isActive={true} />
          <Box
            component='button'
            sx={{ mt: 8 }}
            width='210px'
            height='40px'
            border='1px solid #4083E0'
            borderRadius='4px'
            bgcolor='transparent'
            color='#5181d9'
            onClick={() => props.history.push('/specialities')}>
            Browse All Specialities
          </Box>
        </Grid>

        <Grid xs={10}>
          <Box width='100%' display='flex' flexDirection='column' justifyContent='center' sx={{ mt: 12 }}>
            <Box component='span' color='#5181d9' className='heading' textAlign='center'>
              How Does Our Medical Consultation Program Work?
            </Box>
          </Box>
        </Grid>

        <Grid xs={10}>
          <Box
            sx={{ mt: 4 }}
            width='100%'
            display='flex'
            justifyContent='center'
            alignItems='center'
            borderRadius='4px'
            bgcolor='transparent'>
            <Box>
              <img
                className='how-it-works-image'
                src={howOedworksImage}
                alt='how it works image'
                width={600}
                height='auto'
                loading='lazy'
              />
            </Box>
          </Box>
        </Grid>

        <Grid xs={10} id='review-by-patients'>
          <Box
            display='flex'
            justifyContent='center'
            width='100%'
            flexDirection='column'
            alignItems='center'
            sx={{ mt: 12 }}>
            <span className='heading-normal'>
              Reviews <span className='second-section-text-bold'>By Patients</span>
            </span>
            <Box sx={{ mt: 5 }}>
              <PatientReviews mode={isMobile && 'mob'} />
            </Box>
          </Box>
        </Grid>

        {/* Temporarily removing Review by Doctors section on client request */}
        {/* <Grid xs={10}>
          <Box
            display='flex'
            justifyContent='center'
            width='100%'
            flexDirection='column'
            alignItems='center'
            sx={{ mt: 16, mb: 4 }}>
            <span className='heading-normal'>
              Reviews <span className='second-section-text-bold'>By Doctor</span>
            </span>
          </Box>
          <Box width='100%'>
            <DoctorReviews mode={isMobile && 'mob'} />
          </Box>
        </Grid> */}

        <Grid xs={10} id='health-tips'>
          <Box
            display='flex'
            justifyContent='center'
            width='100%'
            flexDirection='column'
            alignItems='center'
            sx={{ mt: 16, mb: 8 }}>
            <span className='heading-normal'>
              News & <span className='second-section-text-bold'>Articles</span>
            </span>
          </Box>
          <NewsArticles mode={isMobile && 'mob'} />
        </Grid>

        <LandingPageFooter />
      </Grid>
    </>
  );
};

export default LandingPage;
