import React, { useState } from 'react';
import { Box, MenuItem } from '@material-ui/core';
import { NavLink } from 'react-router-dom';

import '../css/dropDownMenu.css';

const DropDownMenu = ({ heading, menuList }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  return (
    <Box
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{ position: 'relative', display: 'inline-block' }}>
      <Box sx={{ cursor: 'pointer' }} className='link login-doctor-link'>
        {heading}
      </Box>

      {isOpen && (
        <Box className='custom-menu-list'>
          {menuList.map((service) => (
            <MenuItem key={service.name}>
              <NavLink to={service.path} className='selected-service'>
                {service.name}
              </NavLink>
            </MenuItem>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default DropDownMenu;
