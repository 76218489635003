import React, { useEffect } from 'react';
import LazyLoad from 'react-lazyload';
import { useSelector, useDispatch } from 'react-redux';
import { Box } from '@material-ui/core';
import { getSpecialities } from '../../data/actions/specialities/specialitiesGet';
import Loading from '../../components/Loading';
import { NavLink } from 'react-router-dom';

import specialityNotAvailable from '../../resources/speciality-not-available.svg';
import { getToken } from '../../selectors';
import { sortByName } from '../../utilities/helperFunctions';

const Specialities = (props) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.specialitiesReducer);
  const token = useSelector((state) => getToken(state));

  useEffect(() => {
    dispatch(getSpecialities(props.isActive, token));
  }, []);

  return (
    <Box id='second-medical-opinion' width='100%'>
      <Box width='100%' display='flex' flexDirection='column' justifyContent='center' sx={{ mt: 12 }}>
        <Box component='span' color='#5181d9' className='heading' textAlign='center' sx={{ mb: 2 }}>
          Find the Right Doctor For You With Ease
        </Box>
        <Box component='span' className='sub-heading'>
          Browse Doctors by Specialty and Book Medical Consultations Online
        </Box>
      </Box>

      {data.specialitiesLoading ? (
        <Box
          width='100%'
          display='flex'
          justifyContent='center'
          alignItems='center'
          flexDirection='row'
          sx={{ mt: 8 }}
          minHeight={'100vh'}>
          {' '}
          <Loading />
        </Box>
      ) : (
        <Box
          width='100%'
          display='flex'
          justifyContent='space-between'
          flexDirection='row'
          flexWrap={'wrap'}
          sx={{ mt: 8 }}
          minHeight={'100vh'}>
          {sortByName(data.specialitiesResult).map((obj, ind) => (
            <Box
              width={{ xs: '100%', md: '48%', lg: '30%' }}
              height='310px'
              display='flex'
              flexDirection='column'
              justifyContent='center'
              alignItems='center'
              bgcolor='#FFFFFF'
              borderRadius='20px'
              sx={{ m: '8px' }}
              className='specialities-card'>
              <Box
                borderRadius='100%'
                width='120px'
                height='120px'
                display='flex'
                justifyContent='center'
                alignItems='center'
                border={obj.is_active ? 'none' : '1px solid #B1B5C2'}
                sx={{ mb: 2 }}>
                <LazyLoad>
                  <img alt='speciality' src={obj.is_active ? obj.icon : specialityNotAvailable}></img>
                </LazyLoad>
              </Box>
              <span>{obj.name}</span>

              <Box
                component={'button'}
                sx={{ mt: 2, color: '#ffffff' }}
                bgcolor='#5181d9'
                borderRadius='8px'
                border='none'
                width='80%'
                height='42px'
                className={!obj.is_active && 'button-disabled'}>
                <NavLink to={`/${obj.slug}`} style={{ color: '#ffffff' }}>
                  {' '}
                  {obj.is_active ? 'Get consultation' : 'Coming Soon'}
                </NavLink>
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default Specialities;
